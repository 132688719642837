import { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import axios from 'utilities/authAxios';
import { demographicRight } from 'utilities/userRole';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import WithLoader from 'components/Custom/WithLoader';
import FormDialog from 'components/Custom/FormDialog';
import Alert from 'components/Custom/Alert';
import AddressPage from './Addresses/AddressPage';
import AddContactDialogForm from './AddContactDialogForm';
import ContactsCard from './ContactsCard';

function ContactsPage(props) {
  const [contacts, setContacts] = useState([]);
  const { accountId } = useParams();
  const hasDemographicRight = useRecoilValue(demographicRight);
  const [refresh, toggleRefresh] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Account/GetContacts/${accountId}`)
      .then((res) => {
        setContacts(res.data);
        props.setLoading(false);
      })
      .catch(() => {
        props.presentError('Server could not be reached. Please refresh the page.');
      });
  }, [refresh]);

  const updateContact = (updatedContactObj) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Account/UpdateCustomerInfo`, updatedContactObj)
      .then((res) => {
        if (res.data) {
          props.presentAlert(
            'success',
            'Contact information updated successfully',
            `${updatedContactObj.firstName} ${updatedContactObj.lastName}`
          );
          toggleRefresh(!refresh);
        } else {
          props.presentAlert(
            'error',
            'Contact information was not updated',
            `${updatedContactObj.firstName} ${updatedContactObj.lastName}`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        props.presentAlert(
          'error',
          err.response?.status === 400
            ? `Contact information was not updated - ${err.response?.data.message}`
            : 'Contact information was not updated',
          `${updatedContactObj.firstName} ${updatedContactObj.lastName}`
        );
      });
  };

  const addContact = (newContact) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Account/AddContact`, newContact)
      .then((res) => {
        if (res.data) {
          // Update UI:
          const newContacts = [...contacts, { ...res.data }];
          setContacts(newContacts);
          props.presentAlert(
            'success',
            'New contact added successfully',
            `${res.data.firstName} ${res.data.lastName}`
          );
        } else {
          props.presentAlert(
            'error',
            'New contact was not added',
            `${newContact.firstName} ${newContact.lastName}`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        props.presentAlert(
          'error',
          'New contact was not added',
          `${newContact.firstName} ${newContact.lastName}`
        );
      });
  };

  const removeContact = (contactId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Account/RemoveContact/${contactId}`)
      .then(() => {
        // Remove contact from UI:
        const newContacts = contacts.filter((contact) => {
          return contact.contactId !== contactId;
        });
        setContacts(newContacts);
        props.presentAlert('success', 'Contact was removed successfully');
      })
      .catch(() => {
        props.presentAlert('error', 'Contact was not removed');
      });
  };

  const contactsMapper = contacts.map((contact, index) => {
    return (
      <ContactsCard
        presentAlert={props.presentAlert}
        removeContact={removeContact}
        key={contact.contactId}
        contact={contact}
        updateContact={updateContact}
        index={index}
      />
    );
  });

  return (
    <div>
      {!props.loading && (
        <>
          {hasDemographicRight && (
            <FormDialog formId="AddContact" buttonText="Add Contact" dialogTitle="Add Contact">
              <AddContactDialogForm addContact={addContact} accountId={accountId} />
            </FormDialog>
          )}
          <Stack direction="column" spacing={2}>
            <TableContainer component={Paper}>
              <Table size="small" id="Contacts-table-contacts">
                <TableHead>
                  <TableRow>
                    <TableCell>User Role</TableCell>
                    <TableCell>Contact Name</TableCell>
                    <TableCell>Email Address</TableCell>
                    <TableCell>Primary Phone</TableCell>
                    <TableCell>Pin Number</TableCell>
                    <TableCell align="center" colSpan={2}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{contactsMapper}</TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <div style={{ marginTop: '16px' }}>
            <Alert childrenId="Addresses">
              <AddressPage
                contacts={contacts}
                accountId={accountId}
                presentAlert={props.presentError}
              />
            </Alert>
          </div>
        </>
      )}
    </div>
  );
}

ContactsPage.propTypes = {
  setLoading: PropTypes.func.isRequired,
  presentError: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  presentAlert: PropTypes.func.isRequired,
};

export default withRouter(WithLoader(ContactsPage));
