import { useState, useEffect } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import axios from 'utilities/authAxios';
import PropTypes from 'prop-types';
import FormDialog from 'components/Custom/FormDialog';
import WithLoader from 'components/Custom/WithLoader';
import { demographicRight } from 'utilities/userRole';
import { primaryAddressAtom } from 'utilities/State';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AddressCard from './AddressCard';
import AddAddressDialogForm from './AddAddressDialogForm';

function AddressPage(props) {
  const [addresses, setAddresses] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [addressTypes, setAddressTypes] = useState({});
  const { accountId, contacts } = props;
  const [refresh, toggleRefresh] = useState(false);
  const hasDemographicRight = useRecoilValue(demographicRight);
  const setPrimaryAddress = useSetRecoilState(primaryAddressAtom);
  const primaryContact = contacts.find((contact) => contact.isPrimary);
  const getProvinces = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Account/GetStates`)
      .then((res) => {
        const formattedStates = res.data.map((x) => {
          return {
            stateProvinceCode: x.stateProvinceCode.trim(),
            stateName: x.stateName,
          };
        });
        setProvinces(formattedStates);
      })
      .catch((err) => console.log(err));
  };

  const getAddressTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/AddressType`)
      .then((res) => {
        setAddressTypes(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Account/GetAddresses/${accountId}`)
      .then((res) => {
        setAddresses(res.data);
        setPrimaryAddress(res.data.find((address) => address.isPrimary));
        props.setLoading(false);
      })
      .catch((err) => console.log(err));

    getAddressTypes();
    getProvinces();
  }, [contacts, refresh]);

  const getAddressTypeDesc = (addressTypeId) => {
    let result = '';
    Object.entries(addressTypes).forEach((entry) => {
      if (entry[0] === addressTypeId.toString()) {
        result = entry[1];
      }
    });
    return result;
  };

  const addAddress = (newAddress) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Account/AddAddress`, newAddress)
      .then((res) => {
        if (res.data) {
          // Update UI:
          const newAddresses = [
            ...addresses,
            { ...res.data, firstName: res.data.firstName, lastName: res.data.lastName },
          ];
          setAddresses(newAddresses);
          props.presentAlert(
            'success',
            'New address added successfully',
            `${getAddressTypeDesc(newAddress.addressTypeId)} - ${res.data.firstName} ${
              res.data.lastName
            }`
          );
        } else {
          props.presentAlert(
            'error',
            'New address was not added',
            `${newAddress.firstName} ${newAddress.lastName}`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        props.presentAlert(
          'error',
          'New address was not added',
          `${newAddress.firstName} ${newAddress.lastName}`
        );
      });
  };

  const updateAddress = (updatedAddress) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Account/UpdateCustomerInfo`, updatedAddress)
      .then((res) => {
        if (res.data) {
          props.presentAlert(
            'success',
            'Address information updated successfully',
            `${getAddressTypeDesc(updatedAddress.addressType)} - ${updatedAddress.firstName} ${
              updatedAddress.lastName
            }`
          );
          toggleRefresh(!refresh);
        } else {
          props.presentAlert(
            'error',
            'Address information was not updated',
            `${updatedAddress.firstName} ${updatedAddress.lastName}`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        props.presentAlert(
          'error',
          'Address information was not updated',
          `${updatedAddress.firstName} ${updatedAddress.lastName}`
        );
      });
  };

  const removeAddress = (addressId) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Account/RemoveAddress/${addressId}`)
      .then(() => {
        const updatedAddresses = addresses.filter((address) => address.addressId !== addressId);
        setAddresses(updatedAddresses);
        props.presentAlert('success', 'Address was removed successfully');
      })
      .catch(() => {
        props.presentAlert('error', `Failed to remove address`);
      });
  };

  const addressesMapper = addresses.map((address, index) => {
    return (
      <AddressCard
        index={index}
        primaryContact={primaryContact}
        key={address.addressId}
        address={address}
        updateAddress={updateAddress}
        addressTypes={addressTypes}
        provinces={provinces}
        getAddressTypeDesc={getAddressTypeDesc}
        presentAlert={props.presentAlert}
        removeAddress={removeAddress}
      />
    );
  });

  return (
    <div>
      {!props.loading && (
        <>
          {hasDemographicRight && (
            <FormDialog formId="AddAddress" buttonText="Add Address" dialogTitle="Add Address">
              <AddAddressDialogForm
                contacts={contacts}
                addAddress={addAddress}
                addressTypes={addressTypes}
                provinces={provinces}
              />
            </FormDialog>
          )}
          <Stack direction="column" spacing={2}>
            <TableContainer component={Paper}>
              <Table size="small" id="Addresses-table-addresses">
                <TableHead>
                  <TableRow>
                    <TableCell>User Role</TableCell>
                    <TableCell>Address Type</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Address 1</TableCell>
                    <TableCell>Address 2</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Postal Code</TableCell>
                    <TableCell align="center" colSpan={2}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{addressesMapper}</TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </>
      )}
    </div>
  );
}

AddressPage.propTypes = {
  accountId: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    })
  ).isRequired,
  presentAlert: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default WithLoader(AddressPage);
